<template>
    <div class="phone-number-container" ref="fullScreenElement">
        <div class="scene" v-if="showLottery">
            <div class="wrap">
                <div class="wall wall-right"></div>
                <div class="wall wall-left"></div>
                <div class="wall wall-top"></div>
                <div class="wall wall-bottom"></div>
                <div class="wall wall-back"></div>
            </div>
            <div class="wrap">
                <div class="wall wall-right"></div>
                <div class="wall wall-left"></div>
                <div class="wall wall-top"></div>
                <div class="wall wall-bottom"></div>
                <div class="wall wall-back"></div>
            </div>
        </div>
        <div class="lottery-title" v-if="lotteryDetail">{{ lotteryDetail.lotteryName }}</div>
        <div class="lottery-title-center" v-if="showNum===1">抽奖马上开始</div>
        <div class="start-num" v-if="showNum===2">{{ countdown }}</div>
        <div class="lottery-score" v-if="showLottery">
            <div class="phone-number animate__animated animate__shakeX">
                {{ phoneNumbers[currentNumberIndex].phoneNumber }}
            </div>
        </div>
        <div class="winner-title" v-if="showWinnerTitle">中奖名单</div>
        <div class="winner-list" v-if="!showLottery" :style="winnerFontSize">
            <div v-for="item in winning" :key="item.participantId"
                 class="winner animate__animated animate__fadeInUp">
                <div v-if="showName">
                    {{ item.name }}
                </div>
                <div v-if="showNumber">
                    {{ item.phoneNumber }}
                </div>
            </div>
        </div>
        <video src="../assets/firework.mp4" autoplay class="video" v-if="showWinnerTitle"></video>
        <vue-particles
            color="#dedede"
            :particleOpacity="0.7"
            :particlesNumber="80"
            shapeType="circle"
            :particleSize="4"
            linesColor="#dedede"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="0.4"
            :linesDistance="150"
            :moveSpeed="3"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
            class="bgc-effect"
        >
        </vue-particles>

    </div>
</template>

<script>
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

export default {
    data() {
        return {
            phoneNumbers: [],
            showNumber: false,
            showName: false,
            currentNumberIndex: 0,
            timer: null,
            displayWinningNumber: false,
            winningNumber: '',
            winningName: '',
            winning: [],
            showLottery: false,
            showWinnerTitle: false,
            socket: null,
            id: null,
            lotteryDetail: null,
            countdown: 3,
            showNum: 1,
            winnerFontSize: "font-size: 3em",
            particleOptions: {
                particles: {
                    number: {
                        value: 80,
                        density: {
                            enable: true,
                            value_area: 800,
                        },
                    },
                    color: {
                        value: '#ffffff',
                    },
                    shape: {
                        type: 'circle',
                    },
                    opacity: {
                        value: 0.5,
                        random: true,
                        anim: {
                            enable: true,
                            speed: 1,
                            opacity_min: 0.1,
                            sync: false,
                        },
                    },
                    size: {
                        value: 3,
                        random: true,
                        anim: {
                            enable: true,
                            speed: 2,
                            size_min: 0.1,
                            sync: false,
                        },
                    },
                    line_linked: {
                        enable: false,
                    },
                    move: {
                        enable: true,
                        speed: 0.2,
                        direction: 'none',
                        random: true,
                        straight: false,
                        out_mode: 'out',
                        bounce: false,
                    },
                },
            },
        };
    },
    created() {
        this.id = this.$route.query.id
        console.log(this.$route)
        this.getLotteryDetail()
    },
    mounted() {
        // this.changeScreen()
        // console.log(id);
        // this.fetchPhoneNumbers(id);
        // this.socket = new WebSocket('ws://localhost:8080/school/large/screen');
        // this.socket = new WebSocket('wss://admin.youmaicloud.cn/large/screen');
        this.socket = new WebSocket(process.env.VUE_APP_SOCKET_URL);
        let that = this
        this.socket.onopen = function () {
            console.log('WebSocket 连接中');
            that.sendMessage("ready")
        };

        this.socket.onmessage = function (event) {
            // 收到后端的消息
            console.log('接收到的消息' + event.data);
            if (event.data === 'start') {
                that.startCountdown()

            } else if (event.data === 'stop') {
                that.stopFlashing()
            } else if (event.data === 'close') {
                that.closeWindow()
            }
        };
    },
    methods: {
        startCountdown() {
            this.showNum = 2
            let that = this
            const countdownInterval = setInterval(() => {
                if (this.countdown > 1) {
                    this.countdown--;
                } else {
                    clearInterval(countdownInterval);
                    that.fetchPhoneNumbers()
                }
            }, 1000)
        },
        getLotteryDetail() {
            // eslint-disable-next-line no-undef
            axios.get("/school/lottery/lottery/detail?lotteryId=" + this.id).then(res => {
                console.log(res, "res")
                if (res.data.code == 200) {
                    this.lotteryDetail = res.data.data
                }
            }).catch(error => {
                console.log(error)
            })
        },
        sendMessage(msg) {
            this.socket.send(msg)
        },
        closeWindow() {
            window.close()
        },
        fetchPhoneNumbers() {
            // eslint-disable-next-line no-undef
            axios.get("/school/participants/list").then(res => {
                console.log(res.data.code, "res")
                if (res.data.code == 200) {
                    this.showNum = 3
                    console.log(res)
                    this.phoneNumbers = res.data.data;
                    this.startFlashing();
                }
            }).catch(error => {
                console.log(error)
            })
        },
        startFlashing() {
            this.timer = setInterval(() => {
                this.showLottery = true;
                this.currentNumberIndex = (this.currentNumberIndex + 1) % this.phoneNumbers.length;
            }, 20); // 控制出现和消失的频率，这里设置为每秒一次
            // eslint-disable-next-line no-undef
            axios.get("/school/lottery/prize/draw?lotteryId=" + this.id).then(res => {
                console.log(res, "999")
                if (res.data.code === 200) {
                    this.winning = res.data.data
                    if (res.data.data.length > 28) {
                        this.winnerFontSize = "font-size: 1em"
                    } else if (res.data.data.length > 20) {
                        this.winnerFontSize = "font-size: 2em"
                    }
                    this.sendMessage("canStop")
                }
            }).catch(error => {
                console.log(error)
            })

        },
        stopFlashing() {

            clearInterval(this.timer);
            this.displayWinningNumber = true;
            this.showName = true;
            this.showNumber = true
            this.showLottery = false;
            this.showWinnerTitle = true
        },
    },
    beforeDestroy() {
        this.socket.close();
    }
};
</script>

<style>

/* 抽奖特效 */
.phone-number-container {
    height: 100vh;
    position: relative;
    width: 100vw;
    overflow: hidden;
    background-image: url("../assets/bgc.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.lottery-score {
    display: flex;
    justify-content: center;
    align-items: center;
}

.winner-list {
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 70vw;
    max-height: 70vh;
    background-color: rgba(255, 255, 255, 0.9);
}

.winner-title {
    z-index: 9;
    font-size: 3em;
    color: #ffffff;
    margin-bottom: 1em;
}

.winner {
    margin: 2px 8px;
    color: #000000;
    padding: 5px 10px;
}

.lottery-title {
    z-index: 9;
    position: absolute;
    top: 10px;
    font-size: 3em;
    color: #ffffff;
    //-webkit-text-stroke: 2px #cccccc;
    text-shadow: 0 0 4px 2px #cccccc;
}

.bgc-effect {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
}

.phone-number {
    font-size: 4em;
    color: #ffffff;
}

.lottery-title-center {
    font-size: 3em;
    color: #ffffff;
    text-shadow: 0 2px #ccc;
}

.start-num {
    color: #ffffff;
    font-size: 5em;
}

.animate__animated.animate__shakeX {
    animation-iteration-count: infinite;
}

.video {
    position: absolute;
    top: 0;
    width: 100vw;
}

/* body::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
} */

.scene {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    perspective: 15px;
    perspective-origin: 50% 50%;
}

.wrap {
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: -500px;
    top: -500px;
    transform-style: preserve-3d;
    animation: move 12s infinite linear;
    animation-fill-mode: forwards;
}

.wrap:nth-child(2) {
    animation: move 12s infinite linear;
    animation-delay: 6s;
}

.wall {
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("../assets/bgc.png");
    background-size: cover;
    opacity: 0;
    animation: fade 12s infinite linear;
}

.wrap:nth-child(2) .wall {
    animation-delay: 6s;
}

.wall-right {
    transform: rotateY(90deg) translateZ(500px);
}

.wall-left {
    transform: rotateY(-90deg) translateZ(500px);
}

.wall-top {
    transform: rotateX(90deg) translateZ(500px);
}

.wall-bottom {
    transform: rotateX(-90deg) translateZ(500px);
}

.wall-back {
    transform: rotateX(180deg) translateZ(500px);
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes move {
    0% {
        transform: translateZ(-500px) rotate(0deg);
    }

    100% {
        transform: translateZ(500px) rotate(0deg);
    }
}

</style>
